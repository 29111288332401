import { Box, Flex, Tab, TabList, Tabs, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { NextLink } from "@looksrare/uikit";
import { getLocalStorageItem, getStorageKey, setLocalStorageItem } from "@looksrare/utils";
import { useAccount } from "wagmi";
import { useState } from "react";
import { SeasonLeaderboardWithHeader } from "@/views/Rewards/components/SeasonLeaderboard/SeasonLeaderboardWithHeader";
import { HistoricSeason } from "@/views/Rewards/components/HistoricSeason";
import { GoldDistributionModal } from "@/components/Modals/GoldDistributionModal/GoldDistributionModal";
import { useUserMidSeasonRewards } from "@/queries/user";
import { Header } from "./components/Header";
import { SeasonTwoMeta } from "./components/SeasonMeta/SeasonTwoMeta";

export enum RewardsViewTab {
  YOUR_REWARDS = "YOUR_REWARDS",
  LEADERBOARD = "LEADERBOARD",
}

export enum RewardsViewSeasonTab {
  SEASON_1 = 1,
  SEASON_2 = 2,
}

interface RewardsViewProps {
  tab?: RewardsViewTab;
  season: number;
}

export const RewardsView = ({ tab = RewardsViewTab.YOUR_REWARDS, season }: RewardsViewProps) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { data: midSeasonRewards, isSuccess } = useUserMidSeasonRewards();

  const gamesGold = midSeasonRewards?.gamesGold ?? 0;
  const dropletsGold = midSeasonRewards?.dropletsGold ?? 0;
  const yYoloGold = midSeasonRewards?.yYoloGold ?? 0;

  const userHasRewards = gamesGold > 0 || dropletsGold > 0 || yYoloGold > 0;

  // Custom snooze check because our hook does not support changing key
  const [isSnoozed, setIsSnoozed] = useState(false);
  const storageKey = yYoloGold
    ? getStorageKey(
        `mid_season_rewards_modal_total_gold_${gamesGold}_droplets_${dropletsGold}_yYolo_${yYoloGold}`,
        address
      )
    : // Avoid changing the key if user doesn't have any yYolo reward
      getStorageKey(`mid_season_rewards_modal_total_gold_${gamesGold}_droplets_${dropletsGold}`, address);

  const isLsSnoozed = !!getLocalStorageItem(storageKey);
  const handleSnooze = () => {
    setIsSnoozed(true);
    setLocalStorageItem(storageKey, true);
  };

  return (
    <>
      <Flex position="relative" width="100%" backgroundColor="ui-bg" justifyContent="center" overflowX="hidden">
        <Box
          position="absolute"
          backgroundImage="url('/images/textures/topography.svg')"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          width="1158px"
          height="1083px"
          zIndex={0}
        />
        <Flex flexDirection="column" alignItems="center" minH="100vh" position="relative" width="100%">
          {/* Nav Banner */}
          <Flex width="100%" px={4} gap={6} borderBottom="1px solid" borderColor="border-01" bg="ui-bg">
            <Tabs variant="blank" index={season - 1} isLazy>
              <TabList>
                <Tab as={NextLink} href="/rewards/season-one">
                  {t("S1 [Complete]")}
                </Tab>
                <Tab as={NextLink} href="/rewards">
                  {t("Season {{number}}", { number: 2 })}
                </Tab>
              </TabList>
            </Tabs>
          </Flex>

          {season === 1 ? (
            <VStack spacing={14} alignItems="stretch" w="100%" px={8} py={16}>
              <HistoricSeason flex={1} />
            </VStack>
          ) : (
            <>
              <Header tab={tab} px={8} width="100%" maxWidth="1200px" />
              {tab === RewardsViewTab.YOUR_REWARDS && <SeasonTwoMeta width="100%" py={16} px={8} maxWidth="1200px" />}
              {tab === RewardsViewTab.LEADERBOARD && (
                <SeasonLeaderboardWithHeader width="100%" py={16} px={8} maxWidth="1200px" />
              )}
            </>
          )}
        </Flex>
      </Flex>
      {userHasRewards && isSuccess && !isLsSnoozed && !isSnoozed && (
        <GoldDistributionModal
          isOpen
          gamesGold={gamesGold}
          dropletsGold={dropletsGold}
          yYoloGold={yYoloGold}
          onClose={handleSnooze}
        />
      )}
    </>
  );
};
