import { useState } from "react";
import { Modal } from "@looksrare/uikit";
import { AnimationView } from "./components/AnimationView";
import { WinSummary } from "./components/WinSummary";

enum Views {
  WIN_ANIMATION = "winAnimation",
  WIN_SUMMARY = "winSummary",
}

interface GoldDistributionModalProps {
  gamesGold: number;
  dropletsGold: number;
  yYoloGold: number;
  isOpen: boolean;
  onClose: () => void;
}

export const GoldDistributionModal = ({
  gamesGold,
  dropletsGold,
  yYoloGold,
  isOpen,
  onClose,
}: GoldDistributionModalProps) => {
  const [view, setView] = useState<Views>(Views.WIN_ANIMATION);
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      {view === Views.WIN_ANIMATION ? (
        <AnimationView onComplete={() => setView(Views.WIN_SUMMARY)} onClose={onClose} />
      ) : (
        <WinSummary gamesGold={gamesGold} dropletsGold={dropletsGold} yYoloGold={yYoloGold} onClose={onClose} />
      )}
    </Modal>
  );
};
