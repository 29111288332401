import { Trans, useTranslation } from "next-i18next";
import { formatNumberToLocale } from "@looksrare/utils";
import { TooltipText } from "@looksrare/uikit";

interface Props {
  spend: number;
  unlock: number;
  volumeToDateDisplay: string;
  volumeToUnlockAll: number;
}
export const UnlockTooltip = ({ spend, unlock, volumeToDateDisplay, volumeToUnlockAll }: Props) => {
  const { t } = useTranslation();
  const volumeToUnlockAllFormatted = formatNumberToLocale(volumeToUnlockAll, 0);
  return (
    <TooltipText sx={{ whiteSpace: "pre-line" }}>
      {t(
        "You can speed up your unlocks by using YOLO tokens to play games. For every {{spend}} YOLO you spend in a game, {{unlock}} YOLO tokens are unlocked!",
        { spend, unlock }
      )}
      <TooltipText as="span">
        {t("YOLO token play volume to date:")}
        <TooltipText as="span" bold>
          {" "}
          {volumeToDateDisplay}
        </TooltipText>
      </TooltipText>
      <TooltipText as="span">
        {t("Volume required to unlock all:")}
        <TooltipText as="span" bold>
          {" "}
          {volumeToUnlockAllFormatted}
        </TooltipText>
      </TooltipText>
    </TooltipText>
  );
};
