import { useEffect, useState } from "react";
import { Button, Divider, Flex, IconButton, IconButtonProps, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useAccount } from "wagmi";
import {
  ArrowRightIcon,
  ButtonProps,
  CheckmarkIcon,
  ConnectWalletButton,
  DiscordIcon,
  Text,
  TwitterIcon,
  WalletIcon,
  useToast,
} from "@looksrare/uikit";
import { useGetFormattedErrorAndTitle, useSignatureRequired } from "@looksrare/utils";
import { useUser } from "@/queries/user";
import { useGetStartOAuthFlowForUser, useOAuthRedirectEvaluation } from "@/hooks/oauth";

enum SocialStep {
  CONNECT,
  LOGIN,
  TWITTER,
  DISCORD,
  COMPLETE,
}

interface SocialsStepProps {
  onComplete: () => void;
}

export const SocialsStep = ({ onComplete }: SocialsStepProps) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { data: user, refetch: refetchUser } = useUser(address!, { enabled: !!address });
  const { signInHandler, isAuthorized, isConnected, isSigning } = useSignatureRequired({
    onAuthSuccess: () => refetchUser(),
  });
  const { toast } = useToast();
  const getFormattedErrorAndTitle = useGetFormattedErrorAndTitle();

  const { connect: connectDiscord } = useGetStartOAuthFlowForUser({
    callbackUrlPath: "/airdrop/early-access",
    socialPlatform: "DISCORD",
  });

  const { connect: connectTwitter } = useGetStartOAuthFlowForUser({
    callbackUrlPath: "/airdrop/early-access",
    socialPlatform: "TWITTER",
  });

  useOAuthRedirectEvaluation({
    disabled: false,
    onSuccess: () => {
      refetchUser();
      toast({
        status: "success",
        title: t("Confirmed"),
        description: t("Your social account was linked successfully"),
      });
    },
    onError: (err) => {
      console.error(err);
      const { title, description } = getFormattedErrorAndTitle(err);
      toast({ status: "error", title, description, dataIdSuffix: "account-oauth-connect" });
    },
  });

  const steps = [
    {
      id: "connect",
      description: t("Connect your wallet"),
      cta: (buttonProps: ButtonProps) => (
        <ConnectWalletButton {...buttonProps}>{t("Connect Wallet")}</ConnectWalletButton>
      ),
      mobileCta: (buttonProps: IconButtonProps) => (
        <IconButton {...buttonProps}>
          <WalletIcon />
        </IconButton>
      ),
    },
    {
      id: "login",
      description: t("Sign message in wallet"),
      cta: (buttonProps: ButtonProps) => (
        <Button onClick={signInHandler} isLoading={isSigning} {...buttonProps}>
          {t("Sign In")}
        </Button>
      ),
      mobileCta: (buttonProps: IconButtonProps) => (
        <IconButton onClick={signInHandler} isLoading={isSigning} {...buttonProps}>
          <ArrowRightIcon />
        </IconButton>
      ),
    },
    {
      id: "twitter",
      description: t("Follow @YOLO_Blast on X"),
      cta: (buttonProps: ButtonProps) => (
        <Button onClick={connectTwitter} rightIcon={<TwitterIcon />} {...buttonProps}>
          {t("Connect")}
        </Button>
      ),
      mobileCta: (buttonProps: IconButtonProps) => (
        <IconButton onClick={connectTwitter} {...buttonProps}>
          <ArrowRightIcon />
        </IconButton>
      ),
    },
    {
      id: "discord",
      description: t("Join the YOLO Games Discord"),
      cta: (buttonProps: ButtonProps) => (
        <Button onClick={connectDiscord} rightIcon={<DiscordIcon />} {...buttonProps}>
          {t("Connect")}
        </Button>
      ),
      mobileCta: (buttonProps: IconButtonProps) => (
        <IconButton onClick={connectDiscord} {...buttonProps}>
          <ArrowRightIcon />
        </IconButton>
      ),
    },
  ];

  const initialIndex = (() => {
    if (!isConnected) {
      return SocialStep.CONNECT;
    }
    if (!isAuthorized) {
      return SocialStep.LOGIN;
    }
    if (!user?.twitterUsername) {
      return SocialStep.TWITTER;
    }
    if (!user?.discordUsername) {
      return SocialStep.DISCORD;
    }
    return SocialStep.COMPLETE;
  })();

  const [activeIndex, setActiveIndex] = useState(initialIndex);

  // Handle updating the activeIndex based on the current state. We fetch the user data as each step is completed.
  useEffect(() => {
    if (!isConnected) {
      if (activeIndex !== SocialStep.CONNECT) {
        setActiveIndex(SocialStep.CONNECT);
      }
      return;
    }
    if (!isAuthorized) {
      if (activeIndex !== SocialStep.LOGIN) {
        setActiveIndex(SocialStep.LOGIN);
      }
      return;
    }
    if (!user?.twitterUsername) {
      if (activeIndex !== SocialStep.TWITTER) {
        setActiveIndex(SocialStep.TWITTER);
      }
      return;
    }
    if (!user?.discordUsername) {
      if (activeIndex !== SocialStep.DISCORD) {
        setActiveIndex(SocialStep.DISCORD);
      }
      return;
    }

    if (activeIndex !== SocialStep.COMPLETE) {
      setActiveIndex(SocialStep.COMPLETE);
    }
  }, [activeIndex, isAuthorized, isConnected, user]);

  // Once the activeIndex is COMPLETE, trigger a timeout to advance the user in 1 second.
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (activeIndex === SocialStep.COMPLETE) {
      timeout = setTimeout(() => {
        onComplete();
      }, 1000);
    }
    return () => {
      // cleanup
      clearTimeout(timeout);
    };
  }, [activeIndex, onComplete]);

  return (
    <Stack px={{ base: 4, md: 12 }} spacing={6} alignItems="center" width="100%">
      <Text width={{ base: "100%", md: "648px" }} textStyle="display-03" bold color="text-01" textAlign="center">
        {t("Get early access")}
      </Text>
      <Stack
        borderRadius="24px"
        width="100%"
        maxWidth="648px"
        px={{ base: 2, md: 12 }}
        py={{ base: 6, md: 16 }}
        gap={9}
        bg="rgba(255, 255, 255, 0.04)"
      >
        <Text color="text-03">
          {t("Complete the steps below to earn your early access")}{" "}
          <Text as="span" color="link-01">
            {t("BOOST Airdrop")}
          </Text>
        </Text>
        <Flex width="100%" gap={4}>
          {/* Column of numbers */}
          <Stack spacing={0} alignItems="center">
            {steps.map((step, index) => {
              const isComplete = activeIndex > index;
              const isFuture = activeIndex < index;

              const { stepIndicatorBg, stepIndicatorBorderColor, dividerColor, textColor } = (() => {
                if (isFuture) {
                  return {
                    stepIndicatorBg: "interactive-02",
                    stepIndicatorBorderColor: "interactive-02",
                    dividerColor: "interactive-02",
                    textColor: "text-disabled",
                  };
                }
                return {
                  stepIndicatorBg: isComplete ? "interactive-03" : "transparent",
                  stepIndicatorBorderColor: isComplete ? "interactive-03" : "interactive-01",
                  dividerColor: isComplete ? "interactive-03" : "interactive-01",
                  textColor: isComplete ? "text-primarybutton" : "text-01",
                };
              })();

              return (
                <Flex key={step.id} flexDirection="column" alignItems="center">
                  {index !== 0 && <Divider height="25px" orientation="vertical" borderColor={dividerColor} />}
                  <Flex
                    width={12}
                    height={12}
                    borderRadius="button"
                    border="1px solid"
                    borderColor={stepIndicatorBorderColor}
                    bg={stepIndicatorBg}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text color={textColor}>{index + 1}</Text>
                  </Flex>
                </Flex>
              );
            })}
          </Stack>
          {/* Column of text & action buttons */}
          <Stack spacing="25px" width="100%">
            {steps.map((step, index) => {
              const { description, id, cta: Cta, mobileCta: MobileCta } = step;
              const textColor = (() => {
                if (activeIndex < index) {
                  return "text-disabled";
                }
                return activeIndex > index ? "interactive-03" : "text-01";
              })();
              return (
                <Flex key={id} alignItems="center" gap={4} width="100%" height="48px" justifyContent="space-between">
                  <Text bold color={textColor} maxWidth={{ base: "168px", md: "100%" }}>
                    {description}
                  </Text>
                  <Flex height="100%" justifyContent="flex-end">
                    {activeIndex === index && <Cta display={{ base: "none", md: "inline-flex" }} />}
                    {activeIndex === index && (
                      <MobileCta
                        aria-label={`airdrop-${step.id}-button`}
                        display={{ base: "inline-flex", md: "none" }}
                      />
                    )}
                    {activeIndex > index && <CheckmarkIcon color="interactive-03" boxSize={12} />}
                  </Flex>
                </Flex>
              );
            })}
          </Stack>
        </Flex>
      </Stack>
    </Stack>
  );
};
