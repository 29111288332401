import { chakra, Center, CenterProps, Flex, Stack } from "@chakra-ui/react";
import { Trans, useTranslation } from "next-i18next";
import { Text, Switcher, SwitcherButton, NextLink, BlastGoldTokenIcon, TokenYoloIcon } from "@looksrare/uikit";
import { RewardsViewTab } from "@/views/Rewards/RewardsView";
import { HeaderCard } from "./HeaderCard";

interface HeaderProps extends CenterProps {
  tab: RewardsViewTab;
}
export const Header = ({ tab, ...props }: HeaderProps) => {
  const { t } = useTranslation();

  const epochStartDate = new Date("2024-08-31T09:00Z");
  const epochEndDate = new Date("2024-09-30T09:00Z");
  const seasonStartDate = new Date("2024-06-12T16:00Z");

  return (
    <Center justifyContent="center" pt={16} {...props}>
      <Stack spacing={8} width="100%" alignItems="center">
        <Stack spacing={4} width="100%" maxWidth="720px">
          <Text textStyle="display-01" bold textAlign="center">
            {t("Season 2")}
          </Text>

          <Trans i18nKey="RewardsHeaderNew">
            <Text textAlign="center" color="link-01">
              Play games and invite friends to earn Points and climb the leaderboard. Every epoch your Points earn{" "}
              <chakra.span fontWeight="bold" color="text-01">
                Blast Gold
              </chakra.span>
              . Convert your Points at the end of the season to get a{" "}
              <chakra.span fontWeight="bold" color="text-01">
                $YOLO
              </chakra.span>{" "}
              airdrop!
            </Text>
          </Trans>
        </Stack>

        <Flex flexDirection={{ base: "column", md: "row" }} alignItems="center" width="100%" gap={4}>
          <HeaderCard
            variant="epoch"
            icon={BlastGoldTokenIcon}
            title={t("Blast Gold")}
            explanation={t("The amount of Blast Gold remaining to be distributed among all players this epoch.")}
            // @TODO-yg Blast gold distribution should be a number from BE, when ready
            description={`[${t("redacted")}]`}
            accentColor="goldrush"
            width="100%"
            maxWidth="568px"
            startDate={epochStartDate}
            endDate={epochEndDate}
            flex={1}
          />

          <HeaderCard
            variant="season"
            icon={TokenYoloIcon}
            title={t("YOLO Tokens")}
            explanation={t(
              "This season’s YOLO Tokens to be distributed among all players. The total will be revealed at the end of Season 1."
            )}
            description={`[${t("redacted")}]`}
            accentColor="link-01"
            width="100%"
            maxWidth="568px"
            startDate={seasonStartDate}
            flex={1}
          />
        </Flex>

        <Flex justifyContent="center">
          <Switcher width="382px" bg="ui-01">
            <SwitcherButton
              flex={1}
              isActive={tab === RewardsViewTab.YOUR_REWARDS}
              as={NextLink}
              href="/rewards"
              bgColor={tab === RewardsViewTab.YOUR_REWARDS ? "ui-bg" : "ui-01"}
            >
              {t("Your Rewards")}
            </SwitcherButton>
            <SwitcherButton
              flex={1}
              isActive={tab === RewardsViewTab.LEADERBOARD}
              as={NextLink}
              href="/rewards/leaderboard"
              bgColor={tab === RewardsViewTab.LEADERBOARD ? "ui-bg" : "ui-01"}
            >
              {t("Leaderboard")}
            </SwitcherButton>
          </Switcher>
        </Flex>
      </Stack>
    </Center>
  );
};
