import { Box, Flex, FlexProps, HStack, SimpleGrid, Skeleton, Stack, VStack, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useAccount } from "wagmi";
import {
  Button,
  Chevron,
  GamesFillIcon,
  HandshakeIcon,
  HelpIcon,
  LiquidityIcon,
  NextLink,
  Popover,
  Text,
  TokenDropletsIcon,
} from "@looksrare/uikit";
import { useUserDailyQuests, useUserMilestones } from "@/hooks/points";
import { useGetDailyQuestMeta, useGetMilestoneState } from "@/views/Rewards/components/hooks";
import { DailyQuestsCountdown } from "@/views/Rewards/components/DailyQuests/DailyQuestsCountdown";
import { SeasonTheme } from "@/views/Rewards/components/SeasonMeta/SeasonTheme";
import { DailyQuestsContainer } from "@/views/Rewards/components/DailyQuests/DailyQuestsContainer";
import { SingleDailyQuest } from "@/views/Rewards/components/DailyQuests/SingleDailyQuest";
import { MilestoneCode, UserMilestones } from "@/types";
import { UserPointsDisplay } from "@/views/Rewards/components/UserPointsDisplay";
import { PlayToEarnModal, ReferralModal } from "@/components/Modals";
import { DropletTooltipText, TokenDropletsCounter } from "@/views/Liquidity/components";
import { gradients } from "@/theme/colors";
import { useLiquidityPoolTotalDroplets } from "@/views/Liquidity/hooks";
import { ProvideLiquidityModal } from "@/components/Modals/ProvideLiquidityModal";
import { Milestone } from "../Milestone";
import {
  DontFallInCta,
  FlipperCta,
  LaserBlastCta,
  MilestoneModal,
  MordCta,
  PtbCta,
  QuantumCta,
  YoloCta,
} from "../MilestoneModals/MilestoneModal";

interface MilestoneWithModalProps extends FlexProps {
  code: MilestoneCode;
  title: string;
  userMilestones?: UserMilestones;
  cta?: JSX.Element;
}

export const MilestoneWithModal = ({ userMilestones, code, title, cta }: MilestoneWithModalProps) => {
  const { t } = useTranslation();
  const disclosureForMilestone = useDisclosure();
  const getMilestoneState = useGetMilestoneState();
  const milestone = userMilestones ? userMilestones[code] : undefined;

  return (
    <>
      <Milestone
        milestoneCode={code}
        milestoneLevels={milestone}
        state={getMilestoneState(milestone || [])}
        title={t(title)}
        minWidth="none"
        maxWidth="none"
        onClick={disclosureForMilestone.onOpen}
      />
      <MilestoneModal
        titleText={t(title)}
        milestoneCode={code}
        ctaElement={cta ?? <></>}
        isOpen={disclosureForMilestone.isOpen}
        onClose={disclosureForMilestone.onClose}
        levels={milestone || []}
      />
    </>
  );
};

export const SeasonTwoMeta = ({ ...props }: FlexProps) => {
  const { t } = useTranslation();

  const { address: connectedAddress } = useAccount();
  const { data: userMilestones } = useUserMilestones(connectedAddress!, { enabled: !!connectedAddress });
  const userDailyQuestsQuery = useUserDailyQuests(connectedAddress);
  const referralModalDisclosure = useDisclosure();
  const playToEarnModalDisclosure = useDisclosure();
  const provideLiquidityModalDisclosure = useDisclosure();

  const { data: dropletsAmountRaw } = useLiquidityPoolTotalDroplets(connectedAddress);
  const dropletsAmount = dropletsAmountRaw ? Number(dropletsAmountRaw) : 0;

  const getDailyQuestMeta = useGetDailyQuestMeta();

  return (
    <Flex {...props}>
      <Stack spacing={4} width="100%">
        <Flex width="100%" gap={8} flexDir={{ base: "column", md: "row" }}>
          {/* User Points */}
          <Stack spacing={2} mb={4}>
            <Text textStyle="detail" bold color="text-03">
              {t("Your Points")}
            </Text>
            <UserPointsDisplay />

            <Popover variant="tooltip" label={<DropletTooltipText />}>
              <HStack mt={2}>
                <Text textStyle="detail" bold color="text-03">
                  {t("liq::Your Liquidity Droplets")}
                </Text>
                <HelpIcon textStyle="detail" boxSize={4} color="text-03" />
              </HStack>
            </Popover>
            <Box
              border="1px solid"
              borderRadius="dialog"
              borderColor="teal.400"
              bgGradient={gradients["ui-gradient-v-02"]}
              p={6}
            >
              <VStack alignItems="flex-start">
                <HStack w="100%" justifyContent="space-between" alignItems="space-between">
                  <TokenDropletsIcon boxSize={12} />
                  <Button
                    as={NextLink}
                    rightIcon={<Chevron direction="right" />}
                    href="/liquidity"
                    size="xs"
                    colorScheme="secondary"
                  >
                    {t("Earn Droplets")}
                  </Button>
                </HStack>
                <HStack alignItems="flex-end">
                  <TokenDropletsCounter textStyle="heading-03" shardAmount={dropletsAmount} />
                  <Text textStyle="body" bold color="gray.400">
                    {t("Droplets")}
                  </Text>
                </HStack>
              </VStack>
            </Box>
          </Stack>

          {/* Daily Quests */}
          <Stack spacing={2} flexGrow={1}>
            <DailyQuestsCountdown nextDay={userDailyQuestsQuery.data?.nextDay} />
            <DailyQuestsContainer>
              {userDailyQuestsQuery.isLoading
                ? [...Array(4)].map((_, index) => <Skeleton key={index} width="100%" height="57px" />)
                : userDailyQuestsQuery.data?.quests.map((quest) => {
                    const { goal, code, points, createdAt, claimedAt } = quest;
                    const { task } = getDailyQuestMeta(code, goal);
                    // @todo viem: Use new Viem commify helper when merged
                    const pointsDisplay = new Intl.NumberFormat("en", {}).format(points);
                    return (
                      <SingleDailyQuest
                        key={`${code}-${goal}`}
                        code={code}
                        task={task}
                        hasClaimed={!!claimedAt}
                        canClaim={!!createdAt}
                        points={pointsDisplay}
                      />
                    );
                  })}
            </DailyQuestsContainer>
          </Stack>
        </Flex>

        <Stack spacing={8} width="100%">
          {/* Season Themes */}
          <Stack spacing={2}>
            <Text textStyle="detail" bold color="text-03">
              {t("Season Themes")}
            </Text>
            <Flex width="100%" gap={2}>
              <SeasonTheme
                title={t("Provide Liquidity")}
                description={t(
                  "Deposit ETH & YOLO to secure Liquidity Pools, earn game fees and end-of-season rewards!"
                )}
                icon={LiquidityIcon}
                onClick={provideLiquidityModalDisclosure.onOpen}
              />
              <SeasonTheme
                title={t("Play and Earn")}
                description={t(
                  "Play games on YOLO Games to earn Points. The riskier the game, the more Points you’ll earn, so playing games like YOLO will net you more Points than Poke the Bear."
                )}
                icon={GamesFillIcon}
                onClick={playToEarnModalDisclosure.onOpen}
              />
              <SeasonTheme
                title={t("Invite Friends")}
                description={t(
                  "Share your referral link with friends to earn referral Points whenever your friend plays a game. Terms apply."
                )}
                icon={HandshakeIcon}
                onClick={referralModalDisclosure.onOpen}
              />
              <ReferralModal {...referralModalDisclosure} />
              <PlayToEarnModal {...playToEarnModalDisclosure} />
              <ProvideLiquidityModal {...provideLiquidityModalDisclosure} />
            </Flex>
          </Stack>
          {/* General Quests */}
          <Stack spacing={2}>
            <Text textStyle="detail" bold color="text-03">
              {t("General Quests")}
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} width="100%" gap={2}>
              <MilestoneWithModal userMilestones={userMilestones} code="PROFILE_SETUP" title="Profile Setup" />
              <MilestoneWithModal userMilestones={userMilestones} code="POINTS" title="Double Dippin'" />
            </SimpleGrid>
          </Stack>

          {/*  LaserBlast */}
          <Stack spacing={2}>
            <Text textStyle="detail" bold color="text-03">
              {t("LaserBlast")}
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} width="100%" gap={2}>
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="LASER_BLAST_ETH"
                title="Independence Day"
                cta={<LaserBlastCta />}
              />
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="LASER_BLAST_ROUND"
                title="Finish the Fight"
                cta={<LaserBlastCta />}
              />
            </SimpleGrid>
          </Stack>

          {/* Flipper */}
          <Stack spacing={2}>
            <Text textStyle="detail" bold color="text-03">
              {t("Flipper")}
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} width="100%" gap={2}>
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="FLIPPER_ETH"
                title="The Flipside"
                cta={<FlipperCta />}
              />
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="FLIPPER_ROUND"
                title="Like a Bad Penny"
                cta={<FlipperCta />}
              />
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="FLIPPER_WIN"
                title="On the Dime"
                cta={<FlipperCta />}
              />
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="FLIPPER_WIN_STREAK"
                title="Weighted Coin"
                cta={<FlipperCta />}
              />
            </SimpleGrid>
          </Stack>

          {/* Quantum */}
          <Stack spacing={2}>
            <Text textStyle="detail" bold color="text-03">
              {t("Quantum")}
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} width="100%" gap={2}>
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="QUANTUM_ETH"
                title="The Scientist"
                cta={<QuantumCta />}
              />
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="QUANTUM_ROUND"
                title="Schrödinger"
                cta={<QuantumCta />}
              />
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="QUANTUM_WIN"
                title="Collapse"
                cta={<QuantumCta />}
              />
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="QUANTUM_WIN_STREAK"
                title="Chain Reaction"
                cta={<QuantumCta />}
              />
            </SimpleGrid>
          </Stack>

          {/* Moon or Doom */}
          <Stack spacing={2}>
            <Text textStyle="detail" bold color="text-03">
              {t("MOON or DOOM")}
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} width="100%" gap={2}>
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="MORD_ETH"
                title="Space Race"
                cta={<MordCta />}
              />
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="MORD_WIN"
                title="To Infinity, and Beyond"
                cta={<MordCta />}
              />
              <MilestoneWithModal userMilestones={userMilestones} code="MORD_ROUND" title="Zero-G" cta={<MordCta />} />
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="MORD_WIN_STREAK"
                title="Precision Engineering"
                cta={<MordCta />}
              />
            </SimpleGrid>
          </Stack>

          {/* Poke the Bear */}
          <Stack spacing={2}>
            <Text textStyle="detail" bold color="text-03">
              {t("Poke the Bear")}
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} width="100%" gap={2}>
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="PTB_ETH"
                title="Thrill Seeker"
                cta={<PtbCta />}
              />
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="PTB_WIN"
                title="Bear Whisperer"
                cta={<PtbCta />}
              />
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="PTB_FUTURE_ROUND"
                title="Sucker for Pain"
                cta={<PtbCta />}
              />
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="PTB_WIN_STREAK"
                title="Immortal"
                cta={<PtbCta />}
              />
            </SimpleGrid>
          </Stack>

          {/* YOLO */}
          <Stack spacing={2}>
            <Text textStyle="detail" bold color="text-03">
              {t("YOLO")}
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} width="100%" gap={2}>
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="YOLO_ETH"
                title="ETH Baller"
                cta={<YoloCta />}
              />
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="YOLO_FUTURE_ROUND"
                title="Over & Over"
                cta={<YoloCta />}
              />
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="YOLO_ROUND"
                title="Russian Roulette"
                cta={<YoloCta />}
              />
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="YOLO_WIN"
                title="The Luck of the Devil"
                cta={<YoloCta />}
              />
            </SimpleGrid>
          </Stack>

          {/* Don't Fall In */}
          <Stack spacing={2}>
            <Text textStyle="detail" bold color="text-03">
              {t("Don't Fall In")}
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} width="100%" gap={2}>
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="DONT_FALL_IN_ETH"
                title="Volcanologist"
                cta={<DontFallInCta />}
              />
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="DONT_FALL_IN_ROUND"
                title="In the Hot Seat"
                cta={<DontFallInCta />}
              />
              <MilestoneWithModal
                userMilestones={userMilestones}
                code="DONT_FALL_IN_WIN"
                title="Lava Lover"
                cta={<DontFallInCta />}
              />
              {/* <MilestoneWithModal
                userMilestones={userMilestones}
                code="DONT_FALL_IN_WIN_STREAK"
                title="Magma-Proof"
                cta={<DontFallInCta />}
              /> */}
            </SimpleGrid>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  );
};
