import { useTranslation } from "next-i18next";
import { Box, Table, TableContainer, Tbody, Th, Thead, Tr, Td, HStack, Spinner } from "@chakra-ui/react";
import {
  Text,
  type TextProps,
  EmptyStateIcon,
  EthTokenIcon,
  TokenYoloIcon,
  PercentChangeLabel,
} from "@looksrare/uikit";
import {
  LiquidityPoolGameStats,
  LiquidityPoolInterval,
  QueryLiquidityPoolsOverviewArgs,
} from "@looksrare/yolo-games-gql-typegen";
import { commify, formatNumberToLocale } from "@looksrare/utils";
import { useState } from "react";
import { useLiquidityPoolsGameOverview } from "../hooks/useLiquidityPoolsGameOverview";
import { gameContractToDisplay, getPercentageChange } from "../utils";
import { TimeIntervalSelectMenu } from "./TimeIntervalSelectMenu";

const CellText = (props: TextProps) => (
  <Text display="inline-flex" alignItems="center" color="text-02" textStyle="detail" {...props} />
);

const PercentChangeLabelCapped = ({ value }: { value: number }) => {
  return <PercentChangeLabel value={Math.min(value, 10_000)} minimumFractionDigits={0} maximumFractionDigits={2} />;
};

export const LiquidityGameOverview = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<QueryLiquidityPoolsOverviewArgs["filters"]>({
    timeInterval: "_1M",
  });

  const gameMetrics = useLiquidityPoolsGameOverview({ filters });
  const hasNoData = gameMetrics.isSuccess && (gameMetrics.data === undefined || gameMetrics.data?.length === 0);

  const handleUpdateTimeInterval = (newTimeInterval: LiquidityPoolInterval) => {
    setFilters((prevState) => ({
      ...prevState,
      timeInterval: newTimeInterval,
    }));
  };

  return (
    <Box>
      <HStack justifyContent="space-between" alignItems="flex-start">
        <Text textStyle="heading-03" bold mb={4}>
          {t("liq::All Games")}
        </Text>
        <TimeIntervalSelectMenu
          currentTimeInterval={filters.timeInterval}
          onSelectTimeInterval={handleUpdateTimeInterval}
        />
      </HStack>
      <TableContainer border="solid 1px" borderColor="border-01" bgGradient="none" borderRadius="dialog" mb={50}>
        <Table>
          <Thead>
            <Tr>
              <Th>{t("liq::Game")}</Th>
              <Th textAlign="right" display="flex" justifyContent="flex-end">
                <Box maxWidth="100px" whiteSpace="normal">
                  {t("liq::Total Rnds Played")}
                </Box>
              </Th>
              <Th textAlign="right">{t("liq::ETH Vol")}</Th>
              <Th textAlign="right">{t("liq::ETH Fees")}</Th>
              <Th textAlign="right">{t("liq::YOLO Vol")}</Th>
              <Th textAlign="right">{t("liq::YOLO Fees")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {(() => {
              if (hasNoData) {
                return (
                  <Tr>
                    <Td colSpan={6} borderBottom={0}>
                      <Box py={8} textAlign="center">
                        <EmptyStateIcon boxSize={16} color="text-disabled" />
                        <Text color="text-disabled" bold>
                          {t("No Data")}
                        </Text>
                      </Box>
                    </Td>
                  </Tr>
                );
              }

              if (gameMetrics.isLoading) {
                return (
                  <Tr>
                    <Td colSpan={6} borderBottom={0}>
                      <Box py={8} textAlign="center">
                        <Spinner />
                      </Box>
                    </Td>
                  </Tr>
                );
              }

              if (gameMetrics.isSuccess) {
                const allGamesTotal = gameMetrics.data.reduce(
                  (accum, metric) => {
                    return {
                      totalPlayedRoundsEth: accum.totalPlayedRoundsEth + metric.totalPlayedRoundsEth,
                      totalPlayedRoundsYolo: accum.totalPlayedRoundsYolo + metric.totalPlayedRoundsYolo,
                      totalVolumeEth: accum.totalVolumeEth + metric.totalVolumeEth,
                      totalFeesEth: accum.totalFeesEth + metric.totalFeesEth,
                      totalVolumeYolo: accum.totalVolumeYolo + metric.totalVolumeYolo,
                      totalFeesYolo: accum.totalFeesYolo + metric.totalFeesYolo,
                      previousTotalVolumeYolo: accum.previousTotalVolumeYolo + metric.previousTotalVolumeYolo,
                      previousTotalVolumeEth: accum.previousTotalVolumeEth + metric.previousTotalVolumeEth,
                      previousTotalFeesEth: accum.previousTotalFeesEth + metric.previousTotalFeesEth,
                      previousTotalFeesYolo: accum.previousTotalFeesYolo + metric.previousTotalFeesYolo,
                      previousTotalPlayedRoundsYolo:
                        accum.previousTotalPlayedRoundsYolo + metric.previousTotalPlayedRoundsYolo,
                      previousTotalPlayedRoundsEth:
                        accum.previousTotalPlayedRoundsEth + metric.previousTotalPlayedRoundsEth,
                    };
                  },
                  {
                    totalPlayedRoundsEth: 0,
                    totalPlayedRoundsYolo: 0,
                    totalVolumeEth: 0,
                    totalFeesEth: 0,
                    totalVolumeYolo: 0,
                    totalFeesYolo: 0,
                    previousTotalFeesEth: 0,
                    previousTotalFeesYolo: 0,
                    previousTotalVolumeEth: 0,
                    previousTotalVolumeYolo: 0,
                    previousTotalPlayedRoundsYolo: 0,
                    previousTotalPlayedRoundsEth: 0,
                  } satisfies Omit<LiquidityPoolGameStats, "gameContract">
                );

                const allGames = {
                  gameContract: "ALL_GAMES" as const,
                  ...allGamesTotal,
                };

                const metricsAggregate = [allGames, ...gameMetrics.data];

                return metricsAggregate.map((metric) => {
                  const { name, color } = gameContractToDisplay(metric.gameContract);
                  return (
                    <Tr key={metric.gameContract} borderBottom="1px solid" borderBottomColor="border-01">
                      <Td>
                        <HStack>
                          <Box boxSize={5} background={color} borderRadius={10} />
                          <CellText>{name}</CellText>
                        </HStack>
                      </Td>
                      <Td textAlign="right">
                        <CellText>
                          {commify(
                            metric.totalPlayedRoundsEth +
                              metric.totalPlayedRoundsYolo -
                              metric.previousTotalPlayedRoundsEth -
                              metric.previousTotalPlayedRoundsYolo
                          )}
                        </CellText>
                        <PercentChangeLabelCapped
                          value={getPercentageChange(
                            metric.totalPlayedRoundsEth + metric.totalPlayedRoundsYolo,
                            metric.previousTotalPlayedRoundsEth + metric.previousTotalPlayedRoundsYolo
                          )}
                        />
                      </Td>
                      <Td textAlign="right">
                        <CellText>
                          {formatNumberToLocale(metric.totalVolumeEth - metric.previousTotalVolumeEth, 0, 2)}
                          <EthTokenIcon boxSize={3} ml={2} />
                        </CellText>
                        <PercentChangeLabelCapped
                          value={getPercentageChange(metric.totalVolumeEth, metric.previousTotalVolumeEth)}
                        />
                      </Td>
                      <Td textAlign="right">
                        <CellText>
                          {formatNumberToLocale(metric.totalFeesEth - metric.previousTotalFeesEth, 0, 2)}
                          <EthTokenIcon boxSize={3} ml={2} />
                        </CellText>
                        <PercentChangeLabelCapped
                          value={getPercentageChange(metric.totalFeesEth, metric.previousTotalFeesEth)}
                        />
                      </Td>
                      <Td textAlign="right">
                        <CellText>
                          {formatNumberToLocale(metric.totalVolumeYolo - metric.previousTotalVolumeYolo, 0, 2)}
                          <TokenYoloIcon boxSize={3} ml={2} />
                        </CellText>
                        <PercentChangeLabelCapped
                          value={getPercentageChange(metric.totalVolumeYolo, metric.previousTotalVolumeYolo)}
                        />
                      </Td>
                      <Td textAlign="right">
                        <CellText>
                          {formatNumberToLocale(metric.totalFeesYolo - metric.previousTotalFeesYolo, 0, 2)}
                          <TokenYoloIcon boxSize={3} ml={2} />
                        </CellText>
                        <PercentChangeLabelCapped
                          value={getPercentageChange(metric.totalFeesYolo, metric.previousTotalFeesYolo)}
                        />
                      </Td>
                    </Tr>
                  );
                });
              }
              return null;
            })()}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
